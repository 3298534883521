#move-aed-section{
    .form-group{
        margin-bottom: 20px;
        position: relative;
    }
    .section-title{
        font-size: 24px;
        font-weight: 700;
    }
    .site-name{
        text-align: center;
        font-size: 22px;
        font-weight: 700;
    }
    .w-full{
        width:100%;
        height: 55px;
        border-radius: 0px;
    }   
    .drop-icom{
        position: absolute;
        right: 10px;
        top: 37px;
        z-index: 1;
    }
    .form-group select {
        position: relative;
        ::after{
            position: absolute;
            right: 20px;
            content: '▼';
            color:red;
        }
    }
    table thead tr {
        background-color:  #999999;
    }

    table tbody tr:nth-child(even){
        background-color: #D9D9D9;
    }   
    table tbody tr td {
        border-left: 2px solid #AAC1D4;
        border-right: 2px solid #AAC1D4;
    }
}
