
.dashboard {
    min-height: 100vh;
    background-color: #f8f9fa;
  }
  
  .navbar {
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
  }
  
  .offcanvas {
    width: 280px;
  }
  
  .nav-link {
    color: #333;
    transition: background-color 0.3s;
  }
  
  .nav-link:hover {
    background-color: #e9ecef;
  }
  
  .main-content {
    padding: 20px;
  }
  
  .sidebar-dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  
  .sidebar-dropdown {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
  }
  
  .sidebar-dropdown.show {
    max-height: 500px; /* Adjust this value based on your needs */
  }
  
  .rotate-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
  }
  
  .sidebar-dropdown .nav-link {
    padding-left: 2.5rem;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }
  
  .Sidebar {
    width: 250px;
    position: fixed;
    height: 100%;
  }
  
  .content {
    // margin-left: 250px;
    padding: 20px;
  }
  

  .offcanvas-start {
    width: 250px;
  }
  
#main-layout{
    .my-container{
        width: 95%;
      }
}  



#sidebar-menus{
    .nav-link{
        color:#fff;
    }
    .nav-link:hover{
        color:#000
    }
    .offcanvas-header{
        .btn-close{
            position: relative;
            opacity: 1;
        }
        .btn-close::after{
            position: absolute;
            width: 40px;
            height: 40px;
            top: 0px;
            content: "\2715"; /* Unicode for the cross icon */
            left: 0px;
            color: #fff;
            z-index: 100;
        }
    }

    .active-tab {
      background-color: #e9ecef;
      color: #000;
    }

}